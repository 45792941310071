import { Box, Typography, Button, Grid } from '@material-ui/core'
import RightArrowIcon from '@material-ui/icons/KeyboardArrowRight'
import Image from '../../../components/Image'
import { WorkshopScheduleDay, ScheduledWorkshopData } from '../../../actions/workshopActions'
import React, { useState } from 'react'
const dayAbbr = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
const selectedDayFormatter = new Intl.DateTimeFormat('en-US', { weekday: 'long', month: 'short', day: 'numeric' });
const dateFormatter = {
    format: (date) => {
        const d = new Date(date);
        return `${d.getUTCFullYear()}-${String(d.getUTCMonth() + 1).padStart(2, '0')}-${String(d.getUTCDate()).padStart(2, '0')}`;
    }
}
const timeFormatter = new Intl.DateTimeFormat('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });

/**
 * @typedef {Object} ListViewProps
 * @property {WorkshopScheduleDay[]} workshops
 */

/**
 * @param {ListViewProps} props
 * @returns 
 */
export const WorkshopListView = ({ workshops }) => {
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    /**
     * @type {[Date | null, React.Dispatch<React.SetStateAction<Date | null>>]}
     */
    const [selectedDate, setSelectedDate] = useState(null);
    if (!workshops) return null

    // Get the dates for the week
    const dates = [now];
    for (let i = 1; i < 7; i++) {
        const date = new Date(now);
        date.setDate(date.getDate() + i);
        dates.push(date);
    }

    const renderSelectedDates = () => {
        /**
         * @type {Date[]}
         */
        const toRender = []
        if (selectedDate === null) {
            toRender.push(...dates);
        } else {
            toRender.push(selectedDate);
        }

        return toRender.map(date => {
            const workshopsForDay = workshops.filter(ws => ws._id === dateFormatter.format(date));
            return <Box sx={{ mt: 6, mb: 3 }}>
                <Typography variant="h4" sx={{ px: 1 }}>
                    {now.getDay() === date.getDay()
                        ? 'Today'
                        : selectedDayFormatter.format(date)}
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        px: 1
                    }}
                >
                    {workshopsForDay.length === 0
                        ? <Typography variant="h6" sx={{ mb: 6, mt: 2 }}>No workshops scheduled</Typography>
                        : <Grid container spacing={3} mt={1}>
                            {workshopsForDay[0].workshops.map(ws => <Grid item sm={12} lg={6}><WorkshopListCard key={ws._id} workshop={ws} /></Grid>)}
                        </Grid>
                    }
                </Box>
            </Box>
        })
    }
    return <Box sx={{ mt: 3 }}>
        <Typography variant="h4" sx={{ mb: 3, textTransform: 'uppercase', textAlign: 'center' }}>
            Live
        </Typography>
        <Box
            sx={{
                display: 'flex',
                flexWrap: 'nowrap',
                width: { xs: '100vw', sm: '90vw', md: '70vw', lg: '60vw', xl: '50vw' },
                px: 5,
                mb: 3,
                columnGap: { xs: 0.75, sm: 2, md: 3, lg: 4, xl: 5 },
                justifyContent: 'center',
            }}
        >
            {dates.map((date, index) => (
                <WeekdayBox
                    key={"date-" + index}
                    date={date}
                    onClick={() => {
                        console.debug("Clicked date", date)
                        console.debug("Selected date", selectedDate)
                        if (selectedDate && dateFormatter.format(selectedDate) === dateFormatter.format(date)) {
                            setSelectedDate(null)
                        } else {
                            setSelectedDate(date)
                        }
                    }}
                    selected={selectedDate && selectedDate.getDay() === date.getDay()}
                />
            ))}
        </Box>
        {renderSelectedDates()}
    </Box>
}

/**
 * @typedef {Object} WeekdayBoxProps
 * @property {Date} date
 * @property {Function} onClick
 * @property {boolean} selected
 */

const weekdayBoxSizing = { xs: '3rem', md: '3.5rem' }

/**
 * 
 * @param {WeekdayBoxProps} props
 * @returns 
 */
const WeekdayBox = ({ date, onClick, selected }) => {
    return <Box
        sx={{
            border: selected ? '2px solid #1CABAA' : '2px solid #A7A7A7',
            backgroundColor: selected ? 'rgba(120,200,181,0.3)' : 'none',
            borderRadius: 2,
            p: 0,
            textAlign: 'center',
            cursor: 'pointer',
            width: weekdayBoxSizing,
            height: weekdayBoxSizing,
            minWidth: weekdayBoxSizing,
            minHeight: weekdayBoxSizing,
            alignContent: 'center',
            userSelect: 'none',
        }}
        onClick={onClick}
    >
        <div>
            <Typography variant="subtitle2" sx={{ fontWeight: 600, fontsize: { xs: '0.7rem', md: '1rem' } }}>
                {dayAbbr[date.getDay()]}
            </Typography>
            <Typography variant="subtitle2" sx={{ fontWeight: 600, fontsize: { xs: '0.7rem', md: '1rem', } }}>
                {date.getDate()}
            </Typography>
        </div>
    </Box>
}

/**
 * @typedef {Object} WorkshopListCardProps
 * @property {ScheduledWorkshopData} workshop
 */

/**
 * 
 * @param {WorkshopListCardProps} props 
 * @returns 
 */
const WorkshopListCard = ({ workshop }) => {
    const workshopDate = new Date(workshop.date);
    const completed = new Date(workshop.endTime) < new Date();
    const textColor = completed ? '#A7A7A7' : '#000'
    const highlightColor = completed ? '#A7A7A7' : '#1CABAA'

    return (
        <Box
            sx={{
                display: 'flex',
                border: `1px solid ${textColor}`,
                borderRadius: 2,
                p: 1,
                userSelect: 'none',
                height: '6rem',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    mx: 1,
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}
            >
                <Typography variant="body1" fontWeight={700} color={textColor} sx={{ textAlign: 'center' }}>
                    {timeFormatter.format(workshopDate)}
                </Typography>
                <Box
                    sx={{
                        border: `1px solid ${highlightColor}`,
                        borderRadius: 4,
                        py: 0.5,
                        px: 1.5,
                        mb: 0.5,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Typography variant="subtitle2" color={highlightColor}>Live</Typography>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center', mx: 2 }}>
                <Image
                    formats={workshop.cover.formats}
                    alt={workshop.title}
                    style={{
                        height: '4rem',
                        aspectRatio: 1,
                        width: '4rem',
                        objectFit: 'cover',
                        objectPosition: 'center',
                        borderRadius: '50%'
                    }}
                />
            </Box>
            <Box sx={{ mx: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', my: 0.5 }} >
                <Typography variant="body1" color={textColor} fontWeight={700}>
                    {workshop.title}
                </Typography>
                <Typography variant="subtitle2" color={textColor} fontWeight={400} component={'span'}>
                    {workshop.host}
                </Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', my: 0.5, ml: 'auto' }}>
                {completed
                    ? <Button
                        variant="outlined"
                        sx={{
                            fontSize: '0.8rem',
                            borderRadius: '40px',
                            height: '2rem',
                            color: textColor,
                            borderColor: textColor
                        }}
                        disabled
                    >
                        <RightArrowIcon />
                    </Button>
                    : <Button
                        variant="outlined"
                        sx={{
                            fontSize: '0.8rem',
                            borderRadius: '40px',
                            padding: 0,
                            height: '2rem',
                            color: textColor,
                            borderColor: textColor
                        }}
                        href={`/yogarenew/workshops/${workshop._id}`}
                    >
                        {workshop.registered ? 'Registered' : 'I want in!'}
                    </Button>
                }
                <Typography variant="subtitle2" color={textColor} sx={{ textAlign: 'center' }}>
                    {workshop.attendees} {completed ? 'Attended' : 'Signed Up'}
                </Typography>
            </Box>
        </Box>
    )
}