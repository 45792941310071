import React, { useEffect, useState } from 'react';
import { TextField, Button, LinearProgress, Grid, Typography, Checkbox, ImageListItem, FormGroup, FormControlLabel } from '@mui/material';
import toast from 'react-hot-toast';
import { createAttachment } from '../../../actions/videoActions';
import FileUpload from '../../../components/FileUpload';
import { createWorkshop, authorizeZoom, getZoomAccessToken, checkZoomAuth, revokeZoomAuth } from '../../../actions/workshopActions';
import ImageCropper from '../../../components/ImageCropper';

const WorkshopForm = ({ workshops, setWorkshops, setShowAddWorkshopDialog }) => {
    const [workshop, setWorkshop] = useState({
        title: '',
        duration: 60,
        host: '',
        date: new Date(),
        cover: null,
        information: '',
        autoRecord: true,
        sendRecording: true,
    });
    const [isUploading, setIsUploading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [newCoverImage, setNewCoverImage] = useState(null);
    const [isZoomAuthorized, setIsZoomAuthorized] = useState(false);
    const [src, setSrc] = useState(null);
    const searchParams = new URLSearchParams(window.location.search)
    const code = searchParams.get('code')

    const handleChange = (event) => {
        const { name, value } = event.target;
        setWorkshop(prev => ({ ...prev, [name]: value }));
    };

    const handleUploadPhoto = async ([attachment]) => {
        const fileReader = new FileReader();
        fileReader.onloadend = () => {
            setSrc(fileReader.result);
        };
        fileReader.readAsDataURL(attachment);
    };

    const handleCropComplete = async (croppedImageUrl) => {
        setIsUploading(true);
        try {
            const response = await fetch(croppedImageUrl);
            const blob = await response.blob();
            const croppedFile = new File([blob], 'croppedImage.jpeg', { type: 'image/jpeg' });

            const data = new FormData();
            data.append('files', croppedFile);
            const loading = toast.loading('Uploading cover image...');
            const uploadResponse = await createAttachment(data);
            if (uploadResponse) {
                toast.dismiss(loading);
                toast.success('Cover image uploaded!');
                setWorkshop((prev) => ({ ...prev, cover: uploadResponse[0] }));
                setNewCoverImage(uploadResponse[0]);
            }
        } catch (error) {
            console.error(error, 'Cover image upload error');
            toast.error('Failed to upload cover.');
        } finally {
            setIsUploading(false);
            setSrc(null); // Reset the source to close the cropper
        }
    };

    const handleZoomAuthorize = async () => {
        const redirectUrl = window.location.href;
        try {
            const response = await authorizeZoom(redirectUrl);
            if (response.url) {
                window.location.href = response.url;
            }
        } catch (error) {
            console.error(error, 'Zoom authorize error');
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const dateObject = new Date(workshop.date);
        const utcDate = dateObject.toISOString();
        workshop.date = utcDate;

        const submissionValues = { ...workshop };

        for (const key in submissionValues) {
            if (submissionValues[key] === '' || submissionValues[key] === null) {
                return toast.error(`Missing ${key} field`);
            }
        }

        try {
            setIsSubmitting(true);
            const response = await createWorkshop(submissionValues);
            if (response) {
                toast.success('Workshop added successfully!');
                setWorkshops([...workshops, response]);
                setWorkshop({
                    title: '',
                    duration: '',
                    host: '',
                    date: new Date(),
                    cover: null,
                    information: '',
                });
                setNewCoverImage(null);
            }
        } catch (error) {
            console.error('Submission error', error);
            toast.error('Failed to add live event.');
        } finally {
            setIsSubmitting(false);
            setShowAddWorkshopDialog(false);
        }
    };

    const zoomCallback = async (code, redirect_url) => {
        try {
            const response = await getZoomAccessToken(code, redirect_url);

            if (response.authorized) {
                localStorage.setItem('zoomAccessToken', response.access_token);
                localStorage.setItem('zoomRefreshToken', response.refresh_token);
                setIsZoomAuthorized(true);
                window.history.pushState({}, document.title, window.location.pathname);
            } else {
                setIsZoomAuthorized(false);
                toast.error('Failed to authorize Zoom account');
                console.error(response);
            }
        } catch (error) {
            console.error(error, 'Zoom access token error');
        }
    }

    const handleRevokeZoom = async () => {
        try {
            const response = await revokeZoomAuth();
            if (response.disconnected) {
                localStorage.removeItem('zoomAccessToken');
                localStorage.removeItem('zoomRefreshToken');
                handleZoomAuthorize();
            } else {
                toast.error('Failed to disconnect Zoom account');
            }
        } catch (error) {
            console.error(error, 'Revoke Zoom error');
            toast.error('Failed to disconnect Zoom account');
        }
    }

    useEffect(() => {
        const redirect_url = window.location.href.split('?')[0];
        if (code) {
            zoomCallback(code, redirect_url)
        }
    }, [code]);

    useEffect(() => {
        const checkZoomAuthStatus = async () => {
            const response = await checkZoomAuth();
            if (response.authorized) {
                localStorage.setItem('zoomAccessToken', response.access_token);
                setIsZoomAuthorized(true);
            } else {
                setIsZoomAuthorized(false);
            }
        }
        checkZoomAuthStatus();
    }, []);

    console.log('workshop', workshop);

    return (
        !isZoomAuthorized ? (
            <Button onClick={handleZoomAuthorize} variant='contained' sx={{ my: 3 }}>
                Connect Zoom to Add Live Events
            </Button>
        ) : (
            <>
                <form onSubmit={handleSubmit} style={{ marginTop: 2 }}>
                    <Typography variant="h4" sx={{ mt: 2, mb: 2 }}>Add New Workshop</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                name="title"
                                label="Title"
                                fullWidth
                                value={workshop.title}
                                onChange={handleChange}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                name="duration"
                                label="Duration (minutes)"
                                type="number"
                                fullWidth
                                value={workshop.duration}
                                onChange={handleChange}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                name="host"
                                label="Host - this is the person who will be hosting your call"
                                fullWidth
                                value={workshop.host}
                                onChange={handleChange}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography>Date & Time</Typography>
                            <input aria-label="Date & Time" type="datetime-local" name="date" value={workshop.date} onChange={handleChange} required />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                name="information"
                                label="Information"
                                fullWidth
                                value={workshop.information}
                                onChange={handleChange}
                                required
                                multiline
                                rows={6}
                                inputProps={{ maxLength: 2000 }}
                                helperText={`${workshop.information.length}/2000`}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox defaultChecked name="autoRecord" value={workshop.autoRecord} onChange={handleChange} />} label="Automatically record meeting" />
                                <FormControlLabel control={<Checkbox defaultChecked name="sendRecording" value={workshop.sendRecording} onChange={handleChange} />} label="Automatically send recording to attendees" />
                            </FormGroup>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            {src ? (
                                <>
                                    <Typography variant="body2" sx={{ mb: 2 }}>Select desired area to crop your image.</Typography>
                                    <ImageCropper src={src} onCropComplete={handleCropComplete} aspect={1} />
                                </>

                            ) : (
                                <>
                                    <Typography variant="body2" sx={{ mb: 2 }}>Please upload an image with a 1:1 aspect ratio (e.g., 800x800 pixels) to ensure proper display. You can also crop it after uploading.</Typography>
                                    <FileUpload onDrop={handleUploadPhoto} fileType="image" fileLabel="cover" maxFiles={1} accept="image/*" />
                                </>
                            )}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <ImageListItem>
                                {newCoverImage && <img src={newCoverImage.url} alt="cover" />}
                            </ImageListItem>
                        </Grid>
                    </Grid>
                    <br />
                    {isSubmitting && <LinearProgress />}
                    <br />
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting || isUploading}
                        type="submit"
                        sx={{ mb: 2 }}
                    >
                        Add Live Event
                    </Button>
                </form>
                <Button
                    variant="outlined"
                    onClick={handleRevokeZoom}
                    sx={{ mb: 5 }}
                >
                    Connect Another Zoom Account
                </Button>
            </>
        )
    );
};

export default WorkshopForm;
