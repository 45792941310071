import React, { useEffect } from 'react'
import { useRoutes, useLocation } from 'react-router-dom'
import { Toaster } from 'react-hot-toast'
import { CssBaseline, ThemeProvider } from '@material-ui/core'
import './i18n'
import RTL from './components/RTL'
import { fbConfig } from './config'
import useScrollReset from './hooks/useScrollReset'
import useSettings from './hooks/useSettings'
import routes from './routes'
import { createCustomTheme } from './theme'
import analytics from './utils/analytics'
import { AnalyticsProvider } from 'use-analytics'
import useAuth from './hooks/useAuth'
import ReactPixel from 'react-facebook-pixel';
import { ErrorFallback } from './ErrorFallback'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { APP_VERSION, RELEASE_STAGE } from './constants'
import { updateUser } from './actions/userActions'

Bugsnag.start({
	// TODO: Use ENV var (REACT_APP_BUGSNAG_API_KEY ***NOT*** REACT_APP_BUGSNA*P*_KEY)
	apiKey: '89a7584bcfee2e57d9ceba07bed96f18',
	plugins: [new BugsnagPluginReact()],
	enabledReleaseStages: ['production', 'testing'],
	appVersion: APP_VERSION,
	releaseStage: RELEASE_STAGE,
})

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

const App = () => {
	const content = useRoutes(routes)
	const { settings } = useSettings()
	const location = useLocation()
	const { user } = useAuth()

	useScrollReset()

	useEffect(() => {
		ReactPixel.init(fbConfig.pixelId, {}, {
			autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
			debug: true, // enable logs
		});
	}, [])

	useEffect(() => {
		if (user) {
			updateUser(user.id, { last_activity: new Date() })
		}
	}, [location])

	const theme = createCustomTheme({
		direction: settings.direction,
		responsiveFontSizes: settings.responsiveFontSizes,
		roundedCorners: settings.roundedCorners,
		theme: settings.theme,
	})

	const errorHandler = (error, info) => {
		console.log("Caught Error: ", error)
		Bugsnag.notify(error)
	}

	return (
		<AnalyticsProvider instance={analytics}>
			<ThemeProvider theme={theme}>
				<ErrorBoundary onError={errorHandler} FallbackComponent={ErrorFallback}>
					<RTL direction={settings.direction}>
						<CssBaseline />
						<Toaster position="bottom-center" />
						{content}
					</RTL>
				</ErrorBoundary>
			</ThemeProvider>
		</AnalyticsProvider>
	)
}

export default App
