import { useRef, useState } from 'react'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast'
import { Avatar, Box, Button, ButtonBase, Divider, ListItemIcon, ListItemText, MenuItem, Popover, Typography } from '@material-ui/core'
import useAuth from '../../hooks/useAuth'
import { SelfImprovementOutlined } from '@material-ui/icons'
import UserIcon from '../../icons/User'
import RestoreIcon from '@material-ui/icons/Restore'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'
import ViewListIcon from '@material-ui/icons/ViewList';
import courseIcon from '../../assets/course-icon-gray.png'

const AccountPopover = () => {
	const anchorRef = useRef(null)
	const { user, logout } = useAuth()
	const navigate = useNavigate()
	const [open, setOpen] = useState(false)

	const handleOpen = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	const handleLogout = async () => {
		try {
			handleClose()
			await logout()
			navigate('/dashboard')
		} catch (err) {
			console.error(err)
			toast.error('Unable to logout.')
		}
	}

	if (!user) return null

	return (
		<>
			<Box
				component={ButtonBase}
				onClick={handleOpen}
				ref={anchorRef}
				sx={{
					alignItems: 'center',
					display: 'flex',
				}}
			>
				<Avatar
					src={`${user?.avatar?.url}`}
					sx={{
						height: 32,
						width: 32,
					}}
				/>
			</Box>
			<Popover
				anchorEl={anchorRef.current}
				anchorOrigin={{
					horizontal: 'center',
					vertical: 'bottom',
				}}
				keepMounted
				onClose={handleClose}
				open={open}
				PaperProps={{
					sx: { width: 240 },
				}}
			>
				<Box sx={{ p: 2 }}>
					<Typography color="textPrimary" variant="subtitle2">
						{user.username}
					</Typography>
					<Typography color="textSecondary" variant="subtitle2">
						{user.siteName}
					</Typography>
				</Box>
				<Divider />
				<Box sx={{ mt: 2 }}>
					<MenuItem component={RouterLink} to="/dashboard/account">
						<ListItemIcon>
							<UserIcon fontSize="small" />
						</ListItemIcon>
						<ListItemText
							primary={
								<Typography color="textPrimary" variant="subtitle2">
									My Account
								</Typography>
							}
						/>
					</MenuItem>
					<MenuItem sx={{ pt: 1.5, pb: 1.5 }} onClick={() => { navigate(`/yogarenew/videos/allclasses`) }}>
						<ListItemIcon><SelfImprovementOutlined fontSize="small" /></ListItemIcon>
						<Typography color="textPrimary" variant="subtitle2">Classes</Typography>
					</MenuItem>
					<MenuItem sx={{ pt: 1.5, pb: 1.5 }} onClick={() => { navigate(`/yogarenew/courses`) }}>
						<ListItemIcon><img src={courseIcon} alt="Course Icon" style={{ width: 15, height: 15 }} /></ListItemIcon>
						<Typography color="textPrimary" variant="subtitle2">Courses</Typography>
					</MenuItem>
					<MenuItem sx={{ pt: 1.5, pb: 1.5 }} onClick={() => { navigate(`/yogarenew/series`) }}>
						<ListItemIcon><ViewListIcon fontSize="small" /></ListItemIcon>
						<Typography color="textPrimary" variant="subtitle2">Series</Typography>
					</MenuItem>
					<MenuItem sx={{ pt: 1.5, pb: 1.5 }} onClick={() => { navigate(`/yogarenew/history`) }}>
						<ListItemIcon><RestoreIcon fontSize="small" /></ListItemIcon>
						<Typography color="textPrimary" variant="subtitle2">History</Typography>
					</MenuItem>
					<MenuItem sx={{ pt: 1.5, pb: 1.5 }} onClick={() => { navigate(`/yogarenew/favorites`) }}>
						<ListItemIcon><FavoriteBorderIcon fontSize="small" /></ListItemIcon>
						<Typography color="textPrimary" variant="subtitle2">Favorites</Typography>
					</MenuItem>
				</Box>
				<Box sx={{ p: 2 }}>
					<Button color="primary" fullWidth onClick={handleLogout} variant="outlined">
						Logout
					</Button>
				</Box>
			</Popover>
		</>
	)
}

export default AccountPopover
