
/**
 * @typedef {Object} ImageProps
 * @property {UploadFileFormats | string | undefined} formats
 * @property {string} alt
 */

/**
 * 
 * @param {ImageProps} props 
 * @returns 
 */
const Image = ({ formats, size, alt, ...props }) => {

    if (!formats) return null;

    size = size ?? 'default';
    let url = ''

    if (typeof formats === 'string') {
        url = formats
    } else {
        if (window.innerWidth < 600 && formats.thumbnail) {
            size = 'thumbnail'
        } else if (window.innerWidth < 960 && formats.small) {
            size = 'small'
        } else if (window.innerWidth >= 1280 && formats.large) {
            size = 'large'
        }

        switch (size) {
            case 'thumbnail':
                url = formats.thumbnail.url
                if (url) break;
            case 'small':
                url = formats.small.url
                if (url) break;
            case 'large':
                url = formats.large.url
                if (url) break;
            default:
                url = Object.values(formats)[0]?.url
                break;
        }

        if (!url) return null
    }

    return (
        <img src={url} alt={alt || ""} {...props} />
    )
}

export default Image