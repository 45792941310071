import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline'
import { Helmet } from 'react-helmet-async'
import { Box, makeStyles, Typography, Button } from '@material-ui/core'
import { paymentsApi } from '../../../../actions/paymentsApi'
import { useEffect } from 'react'
import { getCurrentUser } from '../../../../actions/userActions'
import ReactPixel from 'react-facebook-pixel';

const siteName = 'YogaRenew'

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignContent: 'center',
        textAlign: 'center',
        marginTop: '10vh',
        width: '100%',
        cursor: 'pointer',
    },
    checkIcon: {
        fontSize: '10rem',
        color: theme.palette.success.main,
        alignSelf: 'center'
    },
    button: {
        marginTop: '2rem',
        marginBottom: '2rem',
        paddingTop: '1rem',
        paddingBottom: '1rem',
        alignSelf: 'center',
        [theme.breakpoints.up('xs')]: {
            width: '95%',
        },
        [theme.breakpoints.up('md')]: {
            width: '50%',
        }
    }
}))

const SuccessPage = () => {
    const classes = useStyles()
    const q = new URLSearchParams(window.location.search)
    const sessionId = q.get('session_id')

    useEffect(() => {
        ReactPixel.track('StartTrial', { value: 15, currency: 'USD' })
        completeCheckout()
    }, [])

    const completeCheckout = async () => {
        if (sessionId) {
            await paymentsApi.completeCheckoutSession(sessionId)
            const user = await getCurrentUser(true)
            if (!user || user.error) {
                console.error("Error updating user data after checkout")
            }
            sessionStorage.setItem("user", JSON.stringify(user))
        }
    }

    return (
        <>
            <Helmet>
                <title>Thank you!</title>
            </Helmet>
            <Box className={classes.container}>
                <CheckCircleOutline className={classes.checkIcon} />
                <Typography variant='h3'>
                    Thank you for subscribing!
                </Typography>
                <Button href='/yogarenew/videos' variant='contained' className={classes.button}>
                    Continue to {siteName}
                </Button>
            </Box>
        </>
    )
}

export default SuccessPage
