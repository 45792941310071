import { useState, useEffect } from 'react';
import { fetchScheduledWorkshops, WorkshopScheduleDay, fetchUpcomingWorkshops } from '../../../actions/workshopActions';
import { Box, Typography, makeStyles, CircularProgress } from '@material-ui/core';
import { WorkshopListView } from './list';
import banner from '../../../assets/workshop-banner.jpeg'
import useAuth from '../../../hooks/useAuth';

const useStyles = makeStyles(() => ({
    page: {
        backgroundColor: 'background.paper',
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingBottom: '10rem',
    },
    header: {
        textTransform: 'uppercase',
        marginTop: '2rem',
        marginBottom: '2rem',
        textAlign: 'center',
        zIndex: 2,
        color: 'white'
    },
    banner: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: `url(${banner})`,
        backgroundSize: 'cover',
        backgroundPosition: 'flex-start',
        width: '100%',
    },
    overlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0,0,0,0.2)',
        zIndex: 1,
    },
    button: {
        textTransform: 'uppercase',
        padding: '0.5rem 1.5rem',
        marginTop: '2rem',
    },
}));

export default function Workshops() {
    const { user } = useAuth();
    const classes = useStyles()
    /**
     * @type {[WorkshopScheduleDay[], void]}
     */
    const [workshops, setWorkshops] = useState([])
    const [loading, setLoading] = useState(true)
    const defaultWorkshopCover = 'https://peliuploads.s3.us-east-2.amazonaws.com/workshop_banner_385d890fb3.jpeg';

    const getWorkshops = async () => {
        try {
            // const response = await fetchScheduledWorkshops();
            const response2 = await fetchUpcomingWorkshops();
            // console.log('workshops', response);
            // console.log('upcoming workshops', response2);
            const groupedWorkShopObjects = response2.reduce((acc, workshop) => {
                const date = new Date(workshop.date);
                const now = new Date();

                // Calculate endTime based on duration (assuming duration is in minutes)
                const endTime = new Date(date.getTime() + (workshop.duration * 60000));

                // Prepare workshop object with additional fields matching aggregate format
                const enrichedWorkshop = {
                    ...workshop,
                    endTime: endTime,
                    live: date <= now && endTime >= now,
                    registered: workshop.attendees?.includes(user.id) || false,
                    attendees: workshop.attendees?.length || 0,
                    cover: workshop.cover?.formats?.large?.url ||
                        workshop.cover?.formats?.medium?.url ||
                        workshop.cover?.formats?.small?.url ||
                        workshop.cover?.formats?.thumbnail?.url ||
                        workshop.cover?.url || defaultWorkshopCover,
                };

                // Format date string in YYYY-MM-DD format using local timezone
                const dateArray = date.toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit'
                }).split('/');
                const dateString = `${dateArray[2]}-${dateArray[0]}-${dateArray[1]}`;

                const existingDate = acc.find(item => item._id === dateString);

                if (existingDate) {
                    existingDate.workshops.push(enrichedWorkshop);
                } else {
                    acc.push({
                        _id: dateString,
                        workshops: [enrichedWorkshop]
                    });
                }
                return acc;
            }, []);
            // console.log('grouped workshops', groupedWorkShopObjects);
            setWorkshops(groupedWorkShopObjects);
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (!user) return;
        getWorkshops()
    }, [user])

    return (
        <Box className={classes.page}>
            <Box className={classes.banner}>
                <Box className={classes.overlay} />
                <Typography
                    variant="h2"
                    sx={{
                        textTransform: 'uppercase',
                        my: { xs: 6, sm: 10, md: 12, lg: 16, xl: 20 },
                        textAlign: 'center',
                        zIndex: 2,
                        color: 'white'
                    }}
                >
                    LIVE
                </Typography>
            </Box>
            <Box sx={{ marginLeft: '0.5rem', marginRight: '0.5rem' }}>
                {loading
                    ? <CircularProgress />
                    : <WorkshopListView workshops={workshops} />
                }
            </Box>
        </Box >
    )
}