import { createSlice, current } from '@reduxjs/toolkit';
import objFromArray from '../utils/objFromArray';
import { fetchCourseSections } from '../actions/coursesApi';
import { updateCourse } from '../actions/coursesApi';
import { updateCourseSection } from '../actions/courseSectionsApi';
import { updateCourseLecture } from '../actions/courseLecturesApi';
import { fetchLectureContents } from '../actions/lectureElementsApi';

const initialState = {
    isLoaded: false,
    sections: {
        byId: {},
        allIds: []
    },
    lectures: {
        byId: {},
        allIds: []
    },
    actualLectureContent: {
        byId: {},
        allIds: []
    }
};

const slice = createSlice({
    name: 'curriculum',
    initialState,
    reducers: {
        getSectionLectureObjects(state, action) {
            const { sectionLectures, lectureContents } = action.payload;

            state.sections.byId = objFromArray(sectionLectures);
            state.sections.allIds = sectionLectures.map(section => section.id);

            const lectures = sectionLectures.reduce((acc, section) => {
                return [...acc, ...section.course_lectures];
            }, [])

            state.lectures.byId = objFromArray(lectures);
            state.lectures.allIds = lectures.map(lecture => lecture.id);

            if (lectureContents) {
                state.actualLectureContent.byId = objFromArray(lectureContents);
                state.actualLectureContent.allIds = lectureContents.map(lectureContent => lectureContent.id);
            }

            state.isLoaded = true;
        },
        createSection(state, action) {
            const section = action.payload;

            state.sections.byId[section.id] = section;
            state.sections.allIds.push(section.id);
        },
        updateSection(state, action) {
            const section = action.payload;

            Object.assign(state.sections.byId[section.id], section);
        },
        moveSection(state, action) {
            const { courseId, sectionId, newPosition } = action.payload;

            // TODO: Update the position of the section

            let newSectionOrder = state.sections.allIds.filter(_listId => _listId !== sectionId)
            newSectionOrder.splice(newPosition, 0, sectionId)
            state.sections.allIds = newSectionOrder

            updateCourse(courseId, {
                course_sections: state.sections.allIds
            });
        },
        clearSection(state, action) {
            const sectionId = action.payload;

            // lectureIds to be removed
            const { lectureIds } = state.sections.byId[sectionId];

            // Delete the lectureIds references from the section
            state.sections.byId[sectionId].lectureIds = [];

            // Delete the lectures from state
            lectureIds.forEach((lectureId) => {
                delete state.lectures.byId[lectureId];
            });

            state.lectures.allIds = state.lectures.allIds.filter((lectureId) => lectureIds.includes(lectureId));
        },
        deleteSection(state, action) {
            const sectionId = action.payload;

            delete state.sections.byId[sectionId];
            state.sections.allIds = state.sections.allIds.filter((_listId) => _listId !== sectionId);
        },
        duplicateSection(state, action) {
            const { sectionId } = action.payload;

            const originalSection = state.sections.byId[sectionId];

            const newSection = {
                ...originalSection,
                id: sectionId,
                course_lectures: []
            };

            const lectureIds = originalSection.course_lectures.map(lecture => {
                const newLecture = {
                    ...state.lectures.byId[lecture.id],
                    id: sectionId,
                    section: newSection.id
                };

                state.lectures.byId[newLecture.id] = newLecture;
                state.lectures.allIds.push(newLecture.id);
                newSection.course_lectures.push(newLecture.id);

                return newLecture.id;
            });

            state.sections.byId[newSection.id] = newSection;
            state.sections.allIds.push(newSection.id);

            updateCourse(originalSection.course.id, {
                course_sections: [...state.sections.allIds],
                [newSection.id]: {
                    course_lectures: [...lectureIds]
                }
            });
        },
        createLecture(state, action) {
            const lecture = action.payload;

            state.lectures.byId[lecture.id] = lecture;
            state.lectures.allIds.push(lecture.id);

            // Add the lectureId reference to the section
            state.sections.byId[lecture.course_section.id].course_lectures.push(lecture);
        },
        duplicateLecture(state, action) {
            const { lectureId } = action.payload;

            const originalLecture = state.lectures.byId[lectureId];

            const newLectureId = state.lectures.allIds.length + 1;

            const newLecture = {
                ...originalLecture,
                id: newLectureId // Use the same id as the original lecture
            };

            state.lectures.byId[newLecture.id] = newLecture;
            state.lectures.allIds.push(newLecture.id);

            const sectionId = newLecture.course_section.id;
            const updatedCourseLectures = [
                ...state.sections.byId[sectionId].course_lectures,
                newLecture.id
            ];
            state.sections.byId[sectionId].course_lectures = updatedCourseLectures;
        },
        updateLecture(state, action) {
            const lecture = action.payload;

            Object.assign(state.lectures.byId[lecture.id], lecture);
        },
        moveLecture(state, action) {
            const { lectureId, position, sourceSectionId, sectionId } = action.payload;
            // Set source section as a const for updating later
            const sourceSection = state.sections.byId[sourceSectionId];
            // Remove lecture from source section
            state.sections.byId[sourceSectionId].course_lectures = (state.sections.byId[sourceSectionId].course_lectures.filter((lectures) => lectures.id !== lectureId));

            // If sectionId exists, it means that we have to add the lecture to the new section
            if (sectionId) {
                // Set new section as a const for updating later
                const newSection = state.sections.byId[sectionId];
                // Change lecture's sectionId reference
                state.lectures.byId[lectureId].section = sectionId;
                // Push the lectureId to the specified position in the new section
                state.sections.byId[sectionId].course_lectures.splice(position, 0, state.lectures.byId[lectureId]);
                // Update the new section
                updateCourseSection(newSection.id, {
                    course_lectures: state.sections.byId[sectionId].course_lectures
                });
            } else {
                // Push the lectureId to the specified position in the source section
                state.sections.byId[sourceSectionId].course_lectures.splice(position, 0, state.lectures.byId[lectureId]);
            }
            // Update the source section
            updateCourseSection(sourceSection.id, {
                course_lectures: state.sections.byId[sourceSectionId].course_lectures
            });
        },
        deleteLecture(state, action) {
            const lectureId = action.payload;
            let currentState = current(state)
            const { course_section } = currentState.lectures.byId[lectureId];
            let sectionId = course_section.id || course_section
            state.lectures.allIds = currentState.lectures.allIds.filter((_lectureId) => _lectureId !== lectureId);
            state.sections.byId[sectionId].course_lectures = (currentState.sections.byId[sectionId].course_lectures.filter((_lectureId) => _lectureId !== lectureId));
            delete state.lectures.byId[lectureId];
        },
        createContent(state, action) {
            const content = action.payload;

            state.actualLectureContent.byId[content.id] = content;
            state.actualLectureContent.allIds.push(content.id);

            // Add the contentId reference to the lecture
            state.lectures.byId[content.course_lecture.id].lecture_contents.push(content);
        },
        updateContent(state, action) {
            const content = action.payload;

            Object.assign(state.actualLectureContent.byId[content.id], content);
        },
        moveContent(state, action) {
            const { lectureId, position, contentId } = action.payload;
            // Remove content from  the state
            state.actualLectureContent.allIds = state.actualLectureContent.allIds.filter((_contentId) => _contentId !== contentId);

            // Push the contentId to the specified position in the state
            state.actualLectureContent.allIds.splice(position, 0, contentId);

            // Update the source lecture
            updateCourseLecture(lectureId, { lecture_contents: state.actualLectureContent.allIds });
        },
        deleteContent(state, action) {
            const contentId = action.payload;
            let currentState = current(state)
            const { course_lecture } = currentState.actualLectureContent.byId[contentId];
            let lectureId = course_lecture.id || course_lecture
            state.actualLectureContent.allIds = currentState.actualLectureContent.allIds.filter((_contentId) => _contentId !== contentId);
            state.lectures.byId[lectureId].lecture_contents = (currentState.lectures.byId[lectureId].lecture_contents.filter((_contentId) => _contentId !== contentId));
            delete state.actualLectureContent.byId[contentId];
        },
    }
});

export const { reducer } = slice;

export const getSectionLectureObjects = (courseId, lectureId = '') => async (dispatch) => {
    try {
        const sectionLectures = await fetchCourseSections(courseId)
        let lectureContents = []
        if (lectureId) {
            lectureContents = await fetchLectureContents(lectureId)
        }

        dispatch(slice.actions.getSectionLectureObjects({ sectionLectures, lectureContents }));
    } catch (error) {
        console.error(error)
    }
}

export const moveSection = (courseId, sectionId, newPosition) => async (dispatch) => {

    dispatch(
        slice.actions.moveSection({
            courseId,
            sectionId,
            newPosition
        })
    )
}

export const deleteSection = (sectionId) => async (dispatch) => {
    dispatch(slice.actions.deleteSection(sectionId));
};

export const moveLecture = (lectureId, position, sourceSectionId, sectionId) => async (dispatch) => {
    dispatch(slice.actions.moveLecture({
        lectureId,
        position,
        sourceSectionId,
        sectionId
    }));
};

export const createSection = (section) => async (dispatch) => {
    dispatch(slice.actions.createSection(section));
}

export const updateSection = (section) => async (dispatch) => {
    dispatch(slice.actions.updateSection(section));
}

export const duplicateSection = (section) => async (dispatch) => {
    dispatch(slice.actions.duplicateSection(section));
}

export const createLecture = (lecture) => async (dispatch) => {
    dispatch(slice.actions.createLecture(lecture));
}

export const updateLecture = (lecture) => async (dispatch) => {
    dispatch(slice.actions.updateLecture(lecture));
}

export const deleteLecture = (lectureId) => async (dispatch) => {
    dispatch(slice.actions.deleteLecture(lectureId));
}

export const duplicateLecture = (lecture) => async (dispatch) => {
    dispatch(slice.actions.duplicateLecture(lecture));
}

export const createContent = (content) => async (dispatch) => {
    dispatch(slice.actions.createContent(content));
}

export const updateContent = (content) => async (dispatch) => {
    dispatch(slice.actions.updateContent(content));
}

export const moveContent = (lectureId, position, contentId) => async (dispatch) => {
    dispatch(slice.actions.moveContent({
        lectureId,
        position,
        contentId
    }));
}

export const deleteContent = (contentId) => async (dispatch) => {
    dispatch(slice.actions.deleteContent(contentId));
}

export default slice;