import axios from 'axios'
import qs from 'qs'

const nodeURL = process.env.REACT_APP_STRAPI_URL

const headers = () => {
	const accessToken = localStorage.getItem('accessToken')
	const headers = {
		'Content-Type': 'application/json',
		'x-analytics-source': 'web'
	}
	if (accessToken) headers['Authorization'] = `Bearer ${accessToken}`

	return headers
}

/**
 * @typedef {Object} Video
 * @property {string} title
 * @property {string} status
 * @property {boolean} showOnMobileHome
 * @property {string} published_at
 * @property {string} createdAt
 * @property {string} updatedAt
 * @property {string} id
 * @property {object} channel
 * @property {object} user
 * @property {object} video
 * @property {boolean} hasComments
 * @property {Array[object]} alternate_thumbnails
 * @property {object | undefined} thumbnail
 */

/**
 * @param {File} file
 * @param {(percent: number) => void} onProgress
 * @returns {Promise<Video>}
 */
export async function uploadVideo2(file, channelId, seriesId, onProgress) {
	const { name, type, size } = file;

	const strapiResponse = await fetch(`${nodeURL}/videos/upload`, {
		method: 'POST',
		headers: headers(),
		body: JSON.stringify({ name, mime: type, size, channelId, seriesId }),
	});

	if (!strapiResponse.ok) {
		console.error(strapiResponse);
		throw new Error('error occurred while uploading video: ' + strapiResponse.statusText);
	}

	const strapiJson = await strapiResponse.json();

	if (!strapiJson.uploadUrl || !strapiJson.video) {
		throw new Error('invalid backend response');
	}

	const { uploadUrl, video } = strapiJson;

	const request = new XMLHttpRequest();
	request.open('PUT', uploadUrl, true);

	request.upload.onprogress = (e) => {
		if (e.lengthComputable) {
			const percentComplete = (e.loaded / e.total) * 100;
			onProgress(percentComplete);
		}
	};
	request.onloadend = () => {
		console.log(request);
		if (request.status === 200) {
			console.debug('upload complete');
		} else {
			console.error('upload failed');
		}
	};

	request.send(file);

	return video;
}

// *********** CREATE VIDEO (upload a new video) *********** //
export const uploadVideoXHR = (body, responseCallback = () => { }) => {
	const request = new XMLHttpRequest()

	// request.onprogress(e => console.log(e))
	// request.addEventListener('progress', (e) => console.log(e);
	request.addEventListener('loadedmetadata', (e) => console.log(e))
	request.addEventListener('progress', (e) => {
		console.log(e)
		if (e.lengthComputable) {
			var percentComplete = (e.loaded / e.total) * 100
			console.log(percentComplete)
		}
	})
	request.onreadystatechange = () => {
		console.log(request.readyState)
		if (request.readyState === 4) {
			responseCallback(request.response)
		}
	}

	request.open('POST', `${nodeURL}/videos`)

	request.setRequestHeader('Authorization', `Bearer ${localStorage.getItem('accessToken')}`)

	request.send(body)
}

/**
 * 
 * @param {string} uploadId 
 * @param {string} fileName 
 * @param {number} chunkCount 
 * @returns {Promise<{partNumber: number, url: string}[]>}
 */
export const getVideoUploadUrls = async (uploadId, fileName, chunkCount) => {
	const opts = {
		method: 'POST',
		headers: headers(),
		body: JSON.stringify({ uploadId, fileName, chunkCount }),
	}

	const response = await fetch(`${nodeURL}/videos/getuploadurl`, opts).then((data) => data.json())

	return response.urls;
}

export const initiateMultipartUpload = async (fileName, mime) => {
	const opts = {
		method: 'POST',
		headers: headers(),
		body: JSON.stringify({ fileName, mime }),
	}

	const response = await fetch(`${nodeURL}/videos/initiate-multipart-upload`, opts).then((data) => data.json())

	return response
}

export const completeMultiPartUpload = async (uploadId, fileName, parts, channelId, seriesId, mime) => {
	const opts = {
		method: 'POST',
		headers: headers(),
		body: JSON.stringify({ uploadId, fileName, parts, channelId, seriesId, mime }),
	}

	const response = await fetch(`${nodeURL}/videos/complete-multipart-upload`, opts).then((data) => data.json())

	return response
}

/**
 * 
 * @param {File} file 
 * @param {(number) => void} progressCallback 
 * @param {(number) => void} completeCallback
 * @param {(string) => void} errorCallback
 */
export const uploadVideo = async (file, channelId, seriesId, progressCallback = () => { }, errorCallback = () => { }) => {
	const fileName = file.name
	const mime = file.type

	const initiateResponse = await initiateMultipartUpload(fileName, mime)

	const chunkSize = 104857600; // 100MB
	const chunkCount = Math.ceil(file.size / chunkSize);
	const urls = await getVideoUploadUrls(initiateResponse.uploadId, initiateResponse.fileName, chunkCount);

	const promises = [];
	const parts = [];
	const progressTracker = new Array(chunkCount).fill(0);

	async function uploadPart(partNumber, retries = 0) {
		const start = (partNumber - 1) * chunkSize;
		const end = Math.min(file.size, start + chunkSize);
		const blob = file.slice(start, end);
		const { url } = urls.find(u => u.partNumber === partNumber);

		try {
			const res = await axios.put(url, blob, {
				headers: { 'Content-Type': mime },
				onUploadProgress: (progressEvent) => {
					progressTracker[partNumber - 1] = progressEvent.loaded;
					const totalUploadedBytes = progressTracker.reduce((acc, current) => acc + current, 0);
					const overallProgress = Math.round((totalUploadedBytes * 100) / file.size);
					progressCallback(overallProgress);
				},
			});

			parts.push({ PartNumber: partNumber, ETag: res.headers.etag });
		} catch (error) {
			if (retries < 3) {
				console.warn("Failed to upload part, retrying...", error);
				// Reset the progress tracker for the next attempt
				progressTracker[partNumber - 1] = 0;
				const totalUploadedBytes = progressTracker.reduce((acc, current) => acc + current, 0);
				const overallProgress = Math.round((totalUploadedBytes * 100) / file.size);
				progressCallback(overallProgress);
				errorCallback("Retrying upload of part " + partNumber);
				await uploadPart(partNumber, retries + 1);
			} else {
				if (retries === 3) {
					errorCallback("Failed to upload part " + partNumber + " after 3 retries - aborting upload");
				}
				throw error;
			}
		}
	}

	for (let partNumber = 1; partNumber <= chunkCount; partNumber++) {
		promises.push(uploadPart(partNumber));
	}

	await Promise.all(promises);
	const video = await completeMultiPartUpload(initiateResponse.uploadId, initiateResponse.fileName, parts, channelId, seriesId, mime);
	progressCallback(100);
	return video;
}

// *********** COMPLETE VIDEO UPLOAD *********** //

/**
 * 
 * @param {object} data 
 * @returns 
 */
export const completeVideoUpload = async (data) => {
	const opts = {
		method: 'POST',
		headers: headers(),
		body: JSON.stringify(data),
	}

	const response = await fetch(`${nodeURL}/videos`, opts).then((data) => data.json())

	return response
}
// ********** CREATE ATTACHMENT  ********** //
export const createAttachment = async (body) => {
	const opts = {
		method: 'POST',
		headers: {
			Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
		},
		body: body,
	}

	const response = await fetch(`${nodeURL}/upload`, opts).then((data) => data.json())

	return response
}

// *********** FETCH ALL VIDEOS *********** //
export const fetchVideos = async (userId, exclude = ['video-views'], page, perPage, sort, filter) => {
	const opts = {
		method: 'GET',
		headers: headers(),
	}

	// _exclude[]=video-views&_exclude[]=channel&_exclude[]=user
	let excludes = ''
	if (exclude.length > 0) {
		excludes = '_exclude[]='
		exclude.forEach((ex) => {
			excludes += `${ex}&_exclude[]=`
		})
		excludes = excludes.slice(0, -13)
	}

	if (perPage && sort) {

		const start = page * perPage;
		const limit = perPage;

		let channelFilter = '';
		if (filter?.channel) {
			channelFilter = `&channel_eq=${filter.channel}`;
		}

		let titleFilter = '';
		if (filter?.title) {
			titleFilter = `&title_contains=${filter.title}`;
		}

		const response = await fetch(
			`${nodeURL}/videos?user_eq=${userId}&channel_null=false&_limit=${limit}&_start=${start}&_sort=${sort}&${excludes}${channelFilter}${titleFilter}`,
			opts
		).then((data) => data.json());

		return response;
	}

	let fetchedVideos = [];
	let hasMore = true;
	let start = 0;
	const limit = 100;

	while (hasMore) {
		const response = await fetch(`${nodeURL}/videos?user_eq=${userId}&channel_null=false&_limit=${limit}&_start=${start}&${excludes}`, opts)
			.then(data => data.json());

		fetchedVideos = [...fetchedVideos, ...response];

		if (response.length < limit) {
			hasMore = false;
		} else {
			start += limit;
		}
	}

	return fetchedVideos;
}

// *********** TOGGLE VIDEO IN/OUT OF USER FAVORITES **********/
export const toggleFavorite = async (videoId) => {
	const opts = {
		method: 'GET',
		headers: headers(),
	}

	const response = await fetch(`${nodeURL}/favorites/toggle/${videoId}`, opts).then((data) =>
		data.json()
	)

	return response
}

// *********** FETCH ALL VIDEOS *********** //
export const fetchTeachers = async (userId) => {
	const opts = {
		method: 'GET',
		headers: headers(),
	}

	const response = await fetch(`${nodeURL}/videos/teachers?user_eq=${userId}&channel_null=false`, opts).then((data) =>
		data.json()
	)
	return response
}

// *********** FETCH ALL VIDEOS THAT MATCH FILTERS *********** //
export const fetchFilteredVideos = async (publisherId, filterVals) => {
	const opts = {
		method: 'POST',
		headers: headers(),
		body: JSON.stringify(filterVals)
	}

	const response = await fetch(`${nodeURL}/videos/filtered?user_eq=${publisherId}&_limit=250`, opts).then((data) =>
		data.json()
	)

	return response
}

// *********** FETCH A VIDEO (BY ID) *********** //
export const fetchVideo = async (id, populate) => {
	const opts = {
		method: 'GET',
		headers: headers(),
	}

	let url = `${nodeURL}/videos/${id}`;
	if (populate) {
		url += `?${qs.stringify({ populate })}`;
	}

	try {
		const response = await fetch(url, opts);
		if (!response.ok) {
			console.error(response);
			throw new Error('error occurred while fetching video: ' + response.statusText);
		}

		const video = await response.json();

		return video
	} catch (error) {
		console.error(error)
	}
}

export const fetchVideoForPlayback = async (id) => {
	const opts = {
		method: 'GET',
		headers: headers(),
	}

	const response = await fetch(`${nodeURL}/videos/cdnplayback/${id}`, opts).then((data) => data.json())

	return response
}

// *********** START PROCESSING A VIDEO (BY ID) *********** //
export const triggerProcessing = async (id) => {
	const opts = {
		method: 'GET',
		headers: headers(),
	}

	const response = await fetch(`${nodeURL}/videos/process/${id}`, opts).then((data) => data.json())

	return response
}

// *********** START PROCESSING A VIDEO (BY ID) *********** //
export const triggerThumbnails = async (id) => {
	const opts = {
		method: 'GET',
		headers: headers(),
	}

	const response = await fetch(`${nodeURL}/videos/thumbnails/${id}`, opts).then((data) => data.json())

	return response
}

// *********** UPDATE A VIDEO (BY ID) *********** //
/**
 * 
 * @param {string} id 
 * @param {object} details
 * @returns {Promise<Video>}
 */
export const updateVideo = async (id, details) => {
	const opts = {
		method: 'PUT',
		headers: headers(),
		body: JSON.stringify(details),
	}

	const response = await fetch(`${nodeURL}/videos/${id}`, opts);

	if (!response.ok) {
		console.error(response);
		throw new Error('error occurred while updating video: ' + response.statusText);
	}

	return response.json()
}

// *********** DELETE A VIDEO (BY ID) *********** //
export const deleteVideo = async (id) => {
	const opts = {
		method: 'DELETE',
		headers: headers(),
	}

	const response = await fetch(`${nodeURL}/videos/${id}`, opts).then((data) => data.json())

	return response
}

// ***************************************** //
// ******* DRAGGABLE VIDEO FUNCTIONS ******* //
// ***************************************** //

// *********** GET PLAYLIST  *********** //
export const getPlaylist = async (id) => {
	const opts = {
		method: 'GET',
		headers: headers(),
	}
	const response = await fetch(`${nodeURL}/videos/${id}`, opts).then((data) => data.json())
	return response
}

// *********** MOVE A VIDEO CARD *********** //
export const moveVideoCard = async (id, position, channelId) => {
	const opts = {
		method: 'DELETE',
		headers: headers(),
	}

	const response = await fetch(`${nodeURL}/videos/${id}`, opts).then((data) => data.json())

	return response
}

// *********** FETCH VIDEO VIEWS *********** //
export const fetchVideoViews = async (params) => {
	const opts = {
		method: 'GET',
		headers: headers(),
	}

	const query = new URLSearchParams()
	if (params) {
		Object.keys(params).forEach(key => query.append(key, params[key]))
	}

	const response = await fetch(`${nodeURL}/video-views?${query}`, opts).then((data) => data.json())

	return response
}

// *********** FETCH VIDEO VIEWS BY ID *********** //
export const fetchVideoViewsById = async (id) => {
	const opts = {
		method: 'GET',
		headers: headers(),
	}

	const response = await fetch(`${nodeURL}/video-views/${id}`, opts).then((data) => data.json())

	return response
}
// *********** FETCH VIDEO VIEWS BY USER (BY ID) *********** //
export const fetchVideoViewsByUser = async (userId) => {
	const opts = {
		method: 'GET',
		headers: headers(),
	}

	const response = await fetch(`${nodeURL}/video-views?user_eq=${userId}`, opts).then((data) => data.json())

	return response
}

// *********** FETCH VIDEO VIEWS BY LOGGED IN USER *********** //
export const fetchMyHistory = async () => {
	const opts = {
		method: 'GET',
		headers: headers(),
	}

	const response = await fetch(`${nodeURL}/video-views/user-history`, opts).then((data) => data.json())

	return response
}

// *********** FETCH VIDEO VIEWS BY LOGGED IN USER *********** //
export const fetchMyFavorites = async () => {
	const opts = {
		method: 'GET',
		headers: headers(),
	}

	const response = await fetch(`${nodeURL}/favorites/user-favorites`, opts).then((data) => data.json())

	return response
}

// *********** FETCH VIDEO VIEW COUNT *********** //
export const fetchVideoViewsCount = async () => {
	const opts = {
		method: 'GET',
		headers: headers(),
	}

	const response = await fetch(`${nodeURL}/video-views/count`, opts).then((data) => data.json())

	return response
}

// // *********** FETCH FAVORITES *********** //
export const fetchFavorites = async (exclude = ['video-views']) => {
	const opts = {
		method: 'GET',
		headers: headers(),
	}

	let excludes = ''
	if (exclude.length > 0) {
		excludes = '_exclude[]='
		exclude.forEach((ex) => {
			excludes += `${ex}&_exclude[]=`
		})
		excludes = excludes.slice(0, -13)
	}

	let fetchedFavorites = []
	let hasMore = true
	let start = 0
	const limit = 100

	while (hasMore) {
		const response = await fetch(`${nodeURL}/favorites?_limit=${limit}&_start=${start}&${excludes}`, opts)
			.then(data => data.json())

		fetchedFavorites = [...fetchedFavorites, ...response]

		if (response.length < limit) {
			hasMore = false
		} else {
			start += limit
		}
	}

	return fetchedFavorites
}

export const fetchFeaturedVideos = async (userId) => {
	const opts = {
		method: 'GET',
		headers: headers(),
	}

	const response = await fetch(`${nodeURL}/videos/featured?user_eq=${userId}&_limit=10`, opts).then((data) =>
		data.json()
	)

	return response
}

export const fetchNewestVideos = async (userId) => {
	const opts = {
		method: 'GET',
		headers: headers(),
	}

	const response = await fetch(`${nodeURL}/videos?user_eq=${userId}&channel_null=false&_limit=10&_sort=createdAt:DESC`, opts).then((data) =>
		data.json()
	)

	return response
}

export const fetchPopularVideos = async (userId) => {
	const opts = {
		method: 'GET',
		headers: headers(),
	}

	const response = await fetch(`${nodeURL}/videos/recentpopular?user_eq=${userId}`, opts).then((data) =>
		data.json()
	)

	return response
}

export const fetchAllSortedVideos = async (userId, page = 1, pageSize = 10, sortVals, filterVals, abort, paginatedQuery = false) => {
	const opts = {
		method: 'GET',
		headers: headers(),
		signal: abort
	}

	const params = new URLSearchParams()

	if (sortVals) {
		Object.keys(sortVals).forEach((key) => {
			if (sortVals[key]) {
				params.append(key, sortVals[key])
			}
		})
	}

	if (filterVals) {
		Object.keys(filterVals).forEach((key) => {
			if (filterVals[key]) {
				params.append(key, filterVals[key])
			}
		})
	}

	if (paginatedQuery) {
		params.append('paginatedQuery', true)
	}

	const query = (sortVals || filterVals) ? `&${params.toString()}` : ''

	const response = await fetch(`${nodeURL}/videos/find-all-sorted?user_eq=${userId}&channel_null=false&_limit=${pageSize}&_start=${(page - 1) * pageSize}${query}`, opts).then((data) =>
		data.json()
	)

	return response
}

export const countVideos = async (userId) => {
	const opts = {
		method: 'GET',
		headers: headers(),
	}

	const response = await fetch(`${nodeURL}/videos/count?user_eq=${userId}&channel_null=false`, opts).then((data) =>
		data.json()
	)

	return response
}